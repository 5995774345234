<template>
  <div class="MediaProfilesList">
    <banded-section title="Search Media Profiles">
      <template #tooltip>
        <p class="m-b-m">
          This section is for staff only
        </p>

        <p class="m-b-m">
          Manage Media profiles: Use the search function to find media profiles you want to edit or delete.
        </p>

        <p class="m-b-m">
          Use the Add Media Profile if you want to add a new Media profile to the Hypefactors database.
        </p>
      </template>

      <div class="columns">
        <div class="column">
          <heading size="3">
            Search Media Profiles
          </heading>
        </div>

        <div class="is-narrow column">
          <router-link :to="{ name: 'staff.connect.media-profiles.create' }" class="button is-primary">
            Add new
          </router-link>
        </div>
      </div>

      <div class="mediaProfilesSearch columns is-multiline">
        <div class="column is-10">
          <div class="control">
            <input
              v-model="searchQuery"
              type="text"
              class="input"
              placeholder="Search by name, email, company title, company position"
            >
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <country-picker v-model="countries" class="country-picker" />
          </div>
        </div>
      </div>

      <h4 class="title is-4">
        Search results
      </h4>

      <load-more
        :endpoint="mediaProfileSearchUrl"
        width="column is-12"
      >
        <template slot="firstItem">
          <div class="columns">
            <div class="column is-3 is-bold">
              Name
            </div>
            <div class="column is-3 is-bold">
              Job Position
            </div>
            <div class="column is-3 is-bold">
              Company Name
            </div>
            <div class="column is-3 is-bold">
              Actions
            </div>
          </div>
        </template>

        <template slot="item" slot-scope="{ item, removeItem }">
          <media-profiles-list-item :profile="item" @delete="removeItem" />
        </template>
      </load-more>
    </banded-section>
  </div>
</template>

<script>
import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'

import MediaProfilesListItem from '@/components/staff/MediaProfiles/MediaProfilesListItem'

export default {
  components: { MediaProfilesListItem },

  data () {
    return {
      searchQuery: '',
      countries: ''
    }
  },

  computed: {
    mediaProfileSearchUrl () {
      return buildUrlWithParams('/connect/media-profiles', {
        search: this.searchQuery,
        sort: 'a_z',
        countries: [this.countries]
      })
    }
  }
}
</script>
