<template>
  <div :class="$options.name" class="b-b p-b-m">
    <div class="columns">
      <div class="column is-3 is-flex is-aligned-middle">
        <round-avatar :src="profile.picture_url" :acronym-fallback="profile.preferred_name" />

        <span class="m-l-m">{{ profile.preferred_name }}</span>
      </div>

      <div class="column is-3 is-flex is-aligned-middle">
        <template v-if="profile.associations && profile.associations[0]">
          {{ profile.associations[0].role }}
        </template>
      </div>

      <div class="column is-3 is-flex is-aligned-middle">
        <template v-if="profile.associations && profile.associations[0]">
          {{ profile.associations[0].association }}
        </template>
      </div>

      <div class="column is-3 is-flex is-aligned-middle">
        <router-link
          :to="{ name: 'staff.connect.media-profiles.edit', params: { mediaProfileId: profile.id } }"
          class="button is-plain is-dark"
          data-testid="MediaProfilesListItemEdit"
        >
          Edit
        </router-link>

        <button :class="{ 'is-loading': isDeleting }" class="button is-plain is-danger" @click="deleteMediaProfile">
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaProfilesListItem',

  props: {
    profile: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isDeleting: false
    }
  },

  methods: {
    async deleteMediaProfile () {
      try {
        await this.$confirm(`Are you sure you want to delete ${this.profile.preferred_name}'s media profile?`)

        this.isDeleting = true

        await this.$api.delete(`/staff/connect/media-profiles/${this.profile.id}`)

        this.$notify.success('Media Profile removed successfully')

        this.$emit('delete', this.profile)
      } catch (err) {
        if (err === 'cancel') {
          return
        }

        this.$displayRequestError(err)
      }
    }
  }
}
</script>
